import * as Dialog from '@radix-ui/react-dialog'

type ModalProps = {
  trigger?: React.ReactNode
  title?: React.ReactNode
  children: React.ReactNode
  open: boolean
  setIsOpen: (value: boolean) => void
}

const Modal = ({
  trigger,
  title,
  children,
  open = false,
  setIsOpen
}: ModalProps) => (
  <Dialog.Root open={open} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
    <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
    <Dialog.Portal className="relative min-h-screen">
      <Dialog.Overlay className="fixed inset-0 bg-black/80 transition-opacity" />

      <div className="absolute inset-0 flex items-center justify-center text-center">
        <Dialog.Content className="bg-white rounded-lg px-6 py-8 mx-4 text-left overflow-hidden shadow-xl transform transition-all max-w-lg w-full relative z-50">
          {!!title && (
            <Dialog.Title className="text-center font-serif text-2xl mb-4">
              {title}
            </Dialog.Title>
          )}
          <Dialog.Description>{children}</Dialog.Description>
          <Dialog.Close
            asChild
            onClick={() => setIsOpen(false)}
            className="absolute top-4 right-4"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="gray"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Dialog.Close>
        </Dialog.Content>
      </div>
    </Dialog.Portal>
  </Dialog.Root>
)

export default Modal
